<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="T-Registro Bajas"
            :filter="filter"
            search-input
            ref="scrudCasualtyList"
            :single-row="false"
            @rowSelected="selectedCasualties($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3">
                            <s-date
                                v-model="filter.AgtBeginDate"
                                label="Fecha Inicio"
                            ></s-date>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-date
                                v-model="filter.AgtEndDate"
                                label="Fecha Fin"
                            ></s-date>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-select-definition
                                label="Cat. Ocupacional"
                                :def="1039"
                                :dgr="4"
                                v-model="filter.TypeOccupationalCategory"
                                clearable
                            ></s-select-definition>
                        </v-col>
                        <v-col cols="12" md="3">
							<s-select-area 	label="Area" v-model="filter.AreID"  ></s-select-area>
						</v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:Action="{ row }">
  <v-btn x-small color="info" @click="clickActionTxt(row)">
    <v-icon>mdi-file-document</v-icon>{{ " Baja" }}
  </v-btn>
</template>
  <template v-slot:options>
    <v-tooltip bottom="">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text black @click="generateTxtMassive()" small>
          <v-icon>mdi-file-document</v-icon>{{ "txt" }}
        </v-btn>
      </template>
      <span>Generar Archivo de Baja Masiva</span>
    </v-tooltip>
  </template>
        </s-crud>
    </v-card>

</template>

<script>
import SText from "../../components/Utils/SText.vue";
import HmnCasualtyList from "../../services/HumanResource/HmnCasualtyList";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
  components: {
    SText,
    sToolbarPerson,
  },
  data() {
    return {
      itemsCasualties: [],
      filter: {
        TypeOccupationalCategory: "",
        AgtEndDate: "",
        AgtBeginDate: "",
        AreID: "",
        AgtStatus: "",
      },
      config: {
        service: HmnCasualtyList,
        model: {
          AgtID: "ID",
          Action: "",
          AgtBeginDate: "date",
          AgtEndDate: "date",
          AgtTerminationDate: "date",
        },
        headers: [
          { text: "ID", value: "AgtID" },
          { text: "Acción", value: "Action" },
          { text: "N° Doc", value: "PrsDocumentNumber" },
          { text: "Nombres y Apellidos Completos", value: "NtpFullName" },
          { text: "Area", value: "AreName" },
          { text: "Cargo", value: "PstName" },
          { text: "Fecha de Incio", value: "AgtBeginDate" },
          { text: "Fecha de Fin", value: "AgtEndDate" },
          { text: "Fecha de Cese", value: "AgtTerminationDate" },
        ],
      },
    };
  },
  methods: {
    clickActionTxt(item) {
      //console.log(item);
      const textContent = this.generatePerContent([item]);
      //console.log(textContent);
      const blob = new Blob([textContent], { type: "text/plain" });
      this.$fun.saveFileToFolder(blob, "RP_20329725431.per");
    },
    selectedCasualties(item) {
      this.itemsCasualties = item;
    },
    generateTxtMassive() {
      if (this.itemsCasualties && this.itemsCasualties.length > 0) {
        const textContent = this.generatePerContent(this.itemsCasualties);
        const blob = new Blob([textContent], { type: "text/plain" });
        this.$fun.saveFileToFolder(blob, "RP_20329725431.per");
      } else {
        this.$fun.alert(
          "No hay datos disponibles para generar archivos.",
          "warning"
        );
      }
    },
    generatePerContent(rowSelected) {
      return rowSelected
        .flatMap((item) => {
          const data = this.generatePerData(item) ?? null;
          // Se agrega un pipe adicional al final para replicar el "||" del código original
          return data ? this.$fun.generateFileContent(data, 8) + "|" : null;
        })
        .filter((line) => line !== null)
        .join("\n");
    },
    generatePerData(item) {
      const doc = item.DedFormatPersonDocument?.trim() || "";
      const docNum = item.PrsDocumentNumber?.trim() || "";
      const issuing = item.DedHelperNationality ?? "";
      const typeCategory = item.DedHelperCategory || "";
      const beginDate = "";
      const endDate = item.AgtTerminationDate
        ? this.$fun.formatDateView(item.AgtTerminationDate)
        : this.$fun.formatDateView(item.AgtEndDate) || "";
      const motive = item.DedHelperTypeTermination || "";
      return {
        0: doc,
        1: docNum,
        2: issuing,
        3: typeCategory,
        4: 1,
        5: beginDate,
        6: endDate,
        7: motive,
      };
    },
  },
};
</script>